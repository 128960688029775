<template>
  <a-modal
    wrap-class-name="open-cert"
    :visible="visible"
    :title="openText === 'Amazon' ? $t('pages_receive_131') : $t('pages_receive_132')"
    :mask-closable="false"
    :confirm-loading="loading"
    :ok-button-props="{ disabled: !isAgree }"
    :ok-text="loading ? $t('common_text_023') : $t('pages_receive_154')"
    @ok="openCertDown"
    @cancel="$emit('update:visible', false)"
  >
    <a-form
      ref="certRef"
      :model="certForm"
      :rules="certRules"
      class="cert-form"
      layout="vertical"
    >
      <div class="header-tip">{{ $t('pages_receive_140') + (openText === 'Amazon' ? $t('pages_receive_131') : $t('pages_receive_132')) }}</div>
      <a-form-item
        name="name"
        :label="openText + $t('pages_receive_141')"
      >
        <a-input
          v-model:value="certForm.name"
          :placeholder="$t('pages_receive_143') + openText + $t('pages_receive_144')"
          allow-clear
        />
      </a-form-item>
      <a-form-item
        name="email"
        :label="openText + $t('pages_receive_142')"
      >
        <a-input
          v-model:value="certForm.email"
          :placeholder="$t('pages_receive_145')"
          allow-clear
        />
      </a-form-item>
      <a-form-item
        name="country"
        :label="$t('pages_receive_146')"
      >
        <a-input
          v-model:value="certForm.country"
          :placeholder="$t('common_text_006')"
          allow-clear
        />
      </a-form-item>
      <a-form-item
        name="provice"
        :label="$t('pages_receive_147')"
      >
        <a-input
          v-model:value="certForm.provice"
          :placeholder="$t('common_text_006')"
          allow-clear
        />
      </a-form-item>
      <a-form-item
        name="city"
        :label="$t('pages_receive_148')"
      >
        <a-input
          v-model:value="certForm.city"
          :placeholder="$t('common_text_006')"
          allow-clear
        />
      </a-form-item>
      <a-form-item
        name="postCode"
        :label="$t('pages_receive_149')"
      >
        <a-input
          v-model:value="certForm.postCode"
          :placeholder="$t('common_text_006')"
          allow-clear
        />
      </a-form-item>
      <a-form-item
        name="address"
        :label="$t('pages_receive_150')"
      >
        <a-textarea
          v-model:value="certForm.address"
          :placeholder="$t('common_text_006')"
          allow-clear
        />
      </a-form-item>
      <a-form-item>
        <a-checkbox v-model:checked="isAgree">
          {{ $t('pages_receive_151') }}{{ openText }}{{ $t('pages_receive_152') }}
        </a-checkbox>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { ref, reactive, toRefs, watchEffect, inject } from 'vue'
import i18n from '@/locale'
import { _receive } from '@/api'
export default {
  name: 'OpenCert',
  props: {
    visible: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    openText: {
      type: String,
      default: () => {
        return 'Amazon'
      }
    },
    vaInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup (props, ctx) {
    const validateArea = (rule, value) => {
      if (!value) {
        if (rule.field === 'country') return Promise.reject(i18n.global.t('common_text_006') + i18n.global.t('pages_receive_146'))
        if (rule.field === 'provice') return Promise.reject(i18n.global.t('common_text_006') + i18n.global.t('pages_receive_147'))
        if (rule.field === 'city') return Promise.reject(i18n.global.t('common_text_006') + i18n.global.t('pages_receive_148'))
      }
      if (!((/^[\x00-\xff]*$/).test(value))) return Promise.reject(i18n.global.t('pages_receive_153'))
      return Promise.resolve()
    }

    const certRef = ref(null)

    const state = reactive({
      themeData: inject('$themeData'),
      isAgree: false,
      loading: false,
      certForm: {
        name: '',
        email: '',
        address: '',
        postCode: '',
        businessType: '',
        country: '',
        provice: '',
        city: ''
      },
      certRules: {
        name: [{ required: true }],
        email: [{ required: true }],
        address: [{ required: true }],
        postCode: [{ required: true }],
        country: [{ required: true, validator: validateArea }],
        provice: [{ required: true, validator: validateArea }],
        city: [{ required: true, validator: validateArea }]
      },
      vaInfo: {}
    })

    watchEffect(() => {
      state.vaInfo = props.vaInfo
    })

    const openCertDown = () => {
      certRef.value.validateFields()
        .then(values => {
          state.loading = true
          const { id, businessChildType } = state.vaInfo
          const data = Object.assign({}, state.certForm, {
            id,
            businessType: businessChildType
          })
          return _receive.downOpenCert(data)
        })
        .then(res => {
          state.loading = false
          state.isAgree = false
          certRef.value.resetFields()
          ctx.emit('update:visible', false)
        })
        .catch(err => console.log(err))
    }

    return {
      certRef,
      openCertDown,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
.open-cert {
  .cert-form {
    .header-tip {
      font-size: 16px;
      font-weight: 500;
      margin: -5px 0 24px 0;
    }
  }
}
</style>

